@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Roobert";
    src: local("Roobert"),
        url("./fonts/RoobertTRIAL-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "Roobert";
    src: local("Roobert"),
        url("./fonts/RoobertTRIAL-Regular.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Roobert";
    src: local("Roobert"),
        url("./fonts/RoobertTRIAL-Medium.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "Roobert";
    src: local("Roobert"),
        url("./fonts/RoobertTRIAL-Light.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "Roobert";
    src: local("Roobert"),
        url("./fonts/RoobertTRIAL-SemiBold.ttf") format("truetype");
    font-weight: 600;
}